@charset "utf-8";
@import url('https://fonts.googleapis.com/css?family=Nunito:400,700');
@import '../node_modules/bulmaswatch/darkly/variables';

$primary: #3643d4;

$border-light: lighten($body-background-color, 15%);

$card-background-color: transparentize(lighten($body-background-color, 20%), .6);
$card-header-shadow: none;
$card-header-padding: 0.5em 0.75em;

$navbar-background-color: $grey-dark;
$navbar-dropdown-background-color: $grey-dark;

@import '../node_modules/bulma/bulma';

.App {
    min-height: 100vh;
}